import { Filter } from '@zupr/types/fo'

interface FilterProps {
    ordering: string
    filterKeys: { [key: string]: string | null }
    query: Record<string, any>
}

export const searchFilters = ({
    filterKeys,
    query,
    ordering,
}: FilterProps): Filter => {
    const variables: Filter['variables'] = {
        ordering,
    }

    Object.keys(query).forEach((key) => {
        if (Object.keys(filterKeys).includes(key)) {
            // add to the query (null is removed from query)
            if (filterKeys[key] !== null) {
                variables[filterKeys[key]] = query[key]
            }
        } else {
            variables[key] = query[key]
        }
    })

    // how many parts of the query are filters
    let filterCount = Object.keys(variables).filter((key) =>
        Object.values(filterKeys).includes(key)
    ).length

    // if default ordering dont count it towards the filter count
    if (variables.ordering === ordering) {
        filterCount -= 1
    }

    return {
        variables,
        filterCount,
    }
}
